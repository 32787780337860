import React from 'react';
import PropTypes from 'prop-types';
import { AdSlot as BaseAdSlot } from 'react-dfp';
import get from 'lodash.get';
import { useSiteSettings } from 'context/SiteSettingsContext';
import { dataLayerPush } from 'utils/dataLayer';

AdSlot.propTypes = {
  configId: PropTypes.oneOf([
    'adUnitEpisodePlayerSkinnyAdSpace',
    'adUnitEpisodePlayerTallAdSpace',
    'adUnitLiveScheduleShortAdSpace',
    'adUnitSiteFooterDesktopAdSpace',
    'adUnitBlogDesktopAdSpace',
    'adUnitBlogMobileAdSpace',
  ]).isRequired,
  showUid: PropTypes.string,
};

function AdSlot({ configId, showUid }) {
  const { siteSettings } = useSiteSettings();
  const adUnits = get(siteSettings, ['adSettings', configId]);

  if (!adUnits) {
    // Add console error for devs if site settings loaded but the configId does not exist.
    if (siteSettings) {
      console.error(`Error in AdSlot component: configId "${configId}" is not a valid key on siteSettings`);
    }

    return null;
  }

  // Find the active ad unit based on configuration in CS.
  // If showUid is passed in, we check to see if any ad units are configured
  // to display specifically on this show.
  const activeAdUnit = adUnits.find(({ adSlotConfig }) => {
    const { displayByDefault = false, displayTargeting } = adSlotConfig || {};

    // If no show to filter by and this is default, return this.
    if (!showUid) {
      return displayByDefault;
    }

    const targetShows = get(displayTargeting, 'displayForSpecificShows', []);

    return !!targetShows.find(({ uid }) => uid === showUid);
  }) || adUnits.find(({ adSlotConfig }) => get(adSlotConfig, 'displayByDefault', false));

  if (!activeAdUnit) {
    return null;
  }

  const { adUnit, sizes = [] } = activeAdUnit.adSlotConfig;

  return (
    <div onClick={()=>{dataLayerPush(activeAdUnit?.adSlotConfig?.adUnit, 'campaign_id')}}>
      <BaseAdSlot adUnit={adUnit} sizes={sizes.map(({ width, height }) => [width, height])} />
    </div>
  );
}

export default React.memo(AdSlot);
