import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop, theme } from 'styled-tools';
import useTheme from '@material-ui/core/styles/useTheme';
import get from 'lodash.get';
import BaseIcon from 'components/Icon';
import { flattenBlockStyle } from 'utils/blockStyleUtils';
import quoteImage from 'assets/images/quote.svg'

const transitionMixin = (delay = '0s') => css`
  transform: translateX(${({ $display }) => ($display ? '100vw' : '0')});
  transition: transform 0.2s ${delay} ease-in;
`;

const generateSize = (size, style) => css`
  ${size === 'H1' && `${style}: 4vw;`}
  ${size === 'H2' && `${style}: 3.5vw;`}
  ${size === 'H3' && `${style}: 2vw;`}
  ${size === 'H4' && `${style}: 2.5vw;`}
  ${size === 'H5' && `${style}: 2vw;`}
  ${size === 'H6' && `${style}: 1.5vw;`}
`;

const ImageTextWrapper = styled.div`
  font-family: Roboto Condensed, sans-serif; 
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
  z-index: 1000;
  width: 90%;
  grid-row-gap: 1rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10rem;
  padding-left: 3rem;
  padding-right: 3rem;

  ${props => props.theme.breakpoints.down('sm')} {
    padding: 0;
    padding-bottom: 2rem;
    bottom: 15%;
  }

  &&& {
    ${ifProp('$marginBottom', css`
      margin-bottom: ${prop('$marginBottom')}rem;
    `)}

    ${ifProp('$marginTop', css`
      margin-top: ${prop('$marginTop')}rem;
    `)}

    ${ifProp('$marginLeft', css`
      margin-left: ${prop('$marginLeft')}rem;
    `)}
    
    ${ifProp('$marginRight', css`
      margin-right: ${prop('$marginRight')}rem;
    `)}

    ${ifProp('$paddingLeft', css`
      padding-left: ${prop('$paddingLeft')}rem;
    `)}

    ${ifProp('$paddingRight', css`
      padding-right: ${prop('$paddingRight')}rem;
    `)}

    ${ifProp('$paddingTop', css`
      padding-top: ${prop('$paddingTop')}rem;
    `)}

    ${ifProp('$paddingBottom', css`
      padding-bottom: ${prop('$paddingBottom')}rem;
    `)}

    ${ifProp('$background', css`
      background-color: ${prop('$background')};
    `)}
  }
`;

const Quote = styled.h1`
  font-size: 4vw;
  text-transform: uppercase;
  width: 30ch;
  margin: 0;
  margin-bottom: 1rem;
  ${transitionMixin()}

  ${props => props.theme.breakpoints.down('xs')} {
    font-size: 1.5rem
  }

  ${props => generateSize(props.size, 'font-size')}
`;

const Author = styled.div`
  font-size: 2vw;
  font-weight: 700;
  margin-bottom: 2rem;
  text-transform: uppercase;
  ${transitionMixin('0.3s')}

  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  ${props => generateSize(props.size, 'font-size')}
`;

const CtaButtonContainer = styled.div`
  background-color: ${theme('colors.ttRed')};
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;

  ${props => props.theme.breakpoints.down('sm')} {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 1rem;
  }
`;

const CtaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${transitionMixin('0.5s')}

  &:hover {
    cursor: pointer;
  }
  &:hover ${CtaButtonContainer} {
    transform: scale(1.2);
  }
`;

const CtaText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.5rem;
  text-transform: uppercase;

  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 1.25rem;
  }
`;

const QuoteImage = styled.img`
  position: absolute;
  width: 4vw;
  bottom: 94%;
  right: 97%;
  ${transitionMixin('0.2s')}

  ${props => props.theme.breakpoints.down('sm')} {
    right: 100%;
  }
  ${props => generateSize(props.size, 'width')}
`;

const VIDEO = 'video';

function LivePlayerCoverContent({ data, showVideoPlayer }) {
  const { buttonCta, header, subheader, headerType, subheaderType, additionalStyles } = data || {}
  const { blockStyle } = additionalStyles || {};
  const {
    backgroundColor,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    marginBottom,
    marginTop,
    marginLeft,
    marginRight
  } = flattenBlockStyle(blockStyle);
  const buttonAction = get(data, 'buttonAction', VIDEO);
  const theme = useTheme();

  return (
    <ImageTextWrapper $background={backgroundColor} $marginTop={marginTop} $marginBottom={marginBottom} $marginLeft={marginLeft} $marginRight={marginRight} $paddingLeft={paddingLeft} $paddingRight={paddingRight} $paddingTop={paddingTop} $paddingBottom={paddingBottom}>
      <QuoteImage
        src={quoteImage}
        alt="Quote Icon"
        size={headerType}
        $display={showVideoPlayer}
      />
      {header && <Quote size={headerType} $display={showVideoPlayer}>{header}</Quote>}
      {subheader && <Author size={subheaderType} $display={showVideoPlayer}>—{subheader}</Author>}
      {buttonAction === VIDEO && (
        <CtaWrapper $display={showVideoPlayer}>
          <CtaButtonContainer>
            {buttonCta?.buttonIcon && (
              <BaseIcon icon={buttonCta?.buttonIcon} size={1} color={theme.palette.common.white}></BaseIcon>
            )}              
          </CtaButtonContainer>
          { buttonCta?.buttonCtaName && (
            <CtaText>{buttonCta.buttonCtaName}</CtaText>
          )}           
        </CtaWrapper>
      )}
    </ImageTextWrapper>
  );
}

export default LivePlayerCoverContent;
