import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

PercentFormat.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function PercentFormat({ value, ...props }) {
  if (!value) {
    return;
  }

  return (
    <NumberFormat
      value={value}
      suffix='%'
      displayType='text'
      thousandSeparator
      {...props}
    />
  );
}

export default PercentFormat;
