import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import config from 'config';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { useQuery } from '@apollo/client';
import useInterval from 'hooks/useInterval';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import getLiveScheduleQuery from './getLiveSchedule.graphql';
import SchedulePanel from './components/SchedulePanel'
import Icon from 'components/Icon';

const ScheduleContainer = styled.div`
  border-left: 0.0625rem solid ${theme('colors.mutedGray')};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  color: ${theme('colors.white')};
  position: absolute;
`;

const EmptySchedulePanel = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Header = styled.div`
  display: flex;
  padding: 0 1.75rem;
  overflow: hidden;
  min-height: 2.5rem;

  ${props => props.theme.breakpoints.up('md')} {
    border-bottom: 0.0625rem solid ${theme('colors.mutedGray')};
    padding: 0.625rem;
  }
`;

const InnerHeader = styled.div`
  display: flex;
  width: 100%;

  ${props => props.theme.breakpoints.down('sm')} {
    border-bottom: 0.0625rem solid ${theme('colors.mutedGray')};
    padding: 0.625rem 0;
  }
`;

const Details = styled(Typography)`
  font-size: 0.8125rem;
  font-weight: 500;
  text-transform: uppercase;

  ${ifProp('$header', css`
    font-family: ${theme('typography.fontFamilySecondary')};
    font-weight: 700;
  `)}
`;

const IconButton = styled.span`
  cursor: pointer;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;

  ${props => props.theme.breakpoints.up('sm')} {
    right: 1.75rem;
  }

  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;

Schedule.propTypes = {
  closeMobileDrawer: PropTypes.func,
  isTouchDevice: PropTypes.bool,
};

function Schedule({ closeMobileDrawer, isTouchDevice }) {
  const { data, loading, error } = useQuery(getLiveScheduleQuery, {
    skip: typeof window === 'undefined',
    pollInterval: config.livePlayer.schedule.pollInterval,
  });
  const [scheduledShows, setScheduledShows] = useState([])

  useEffect(() => {
    setupScheduleData()
  }, [data]);

  // Setup interval that tracks current time and updates active schedule items.
  useInterval(() => {
    setupScheduleData();
  }, config.livePlayer.schedule.activeShowCheckInterval)

  function setupScheduleData() {
    const liveScheduleGroups = get(data, 'liveSchedule.scheduleGroups', []);
    const newSchedule = [];
    const current = dayjs();

    liveScheduleGroups.forEach(liveScheduleGroup => liveScheduleGroup?.schedule?.forEach(schedule => {
      const start = schedule.scheduleItem.startDateTimeUtc;
      const end = schedule.scheduleItem.endDateTimeUtc;
      let airStatus = 'pre';

      if(current.isBetween(start, end, null, '[)')) {
        airStatus = 'live'
      } else if(current.isAfter(end, null)) {
        airStatus = 'post'
      }

        newSchedule.push({
          ...schedule.scheduleItem,
          airStatus
        })
    }))

    setScheduledShows(newSchedule)
  }

  return (
    <ScheduleContainer>
      {loading && <Box pt={2} pl={2}>Loading...</Box>}
      {error && (
        <Box mt={3}>
          <Alert severity='error'>
            Failed to load live schedule, please reload to try again.
          </Alert>
        </Box>
      )}
      {!loading && !error && get(data, 'liveSchedule.scheduleGroups', []).length === 0 && (
        <EmptySchedulePanel>
          <Header>
            <InnerHeader>
              <Details $header>tasty<em>live</em> - Check back later for upcoming live shows</Details>
            </InnerHeader>
            <IconButton onClick={() => closeMobileDrawer()}>
              <Icon icon='close' />
            </IconButton>
          </Header>
        </EmptySchedulePanel>
      )}
      {scheduledShows.length > 0 && (
        <SchedulePanel
          isTouchDevice={isTouchDevice}
          closeMobileDrawer={closeMobileDrawer}
          scheduledShows={scheduledShows}
        />
      )}
    </ScheduleContainer>
  );
}

export default React.memo(Schedule);
