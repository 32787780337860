import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { ifProp, prop, theme } from 'styled-tools';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Hidden from '@material-ui/core/Hidden';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DISPLAY_TIMEZONE } from 'utils/constants';
import ActionButtons, { ButtonContainer } from '../ActionButtons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Link from '@material-ui/core/Link';
import { dataLayerPush } from 'utils/dataLayer';
import { Wrapper } from 'components/CardButtons/AddToPlaylist';
import Icon from 'components/Icon';
import LiveIndicatorAnimation from 'components/VideoPlayer/LiveIndicatorAnimation';

const IconContainer = styled.div`
  background-color: ${theme('colors.darkCharcoal')};
  border: 0.0625rem solid ${prop('theme.colors.inputGray')};
  border-radius: 50%;
  height: 0.75rem;
  width: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.25rem;
  opacity: 0.75;
`;

const PlayIcon = styled(Icon)`
  height: 0.5rem;
  display: none;
  padding-left: 0.0675rem;
`;

const ShowContainer = styled.div`
  color: ${theme('colors.mediumGray')};
  display: grid;
  grid-template-columns: 2.5rem 6rem 1fr 0rem;
  padding: 1rem;
  position: relative;
  text-align: left;
  width: 100%;

  * {
    font-weight: 700;
  }


  &:hover {
    background-color: ${theme('colors.darkCharcoal')};
  }

  ${ifProp('$active', css`
    color: ${theme('colors.white')};

    ${IconContainer} {
      background-color: ${theme('colors.ttRed')};
      border: none;
      display: none;
    }
  `)}

  ${ifProp('$post', css`
    ${IconContainer} {
      background-color: ${theme('colors.inputGray')};
      border: none;
    }
  `)}

  ${ifProp('$episode', css`
    position: relative;

    ${IconContainer} {
      background-color: ${theme('colors.limeGreen')};
      border: none;
    }

    ${PlayIcon} {
      display: block;
    }
    

    &:hover {
      text-decoration: none;
      color: ${theme('colors.white')};

      ${IconContainer} {
        opacity: 1;
      }

      ${ButtonContainer} {
        visibility: visible;

        ${Wrapper}:hover {
          button {
            background-color: ${theme('colors.mutedGray')};
          }
          svg {
            fill: ${theme('colors.white')};
          }
        }
      }
    }
  `)}

  ${ifProp({ component: 'button' }, css`
    &:hover {
      text-decoration: none;
    }
  `)}
`;

const ShowTitle = styled(Typography)`
  font-size: 0.8625rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0.25rem;
  font-family: ${theme('fonts.robotoCondensed')};

  ${ifProp('$episode', css`
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  `)}
`;

const ShowTimeGrid = styled.div`
  margin-right: 0.25rem;
  font-family: ${theme('fonts.robotoCondensed')};
`;

const ShowDetails = styled.span`
  font-size: 0.75rem;

  p {
    font-weight: 300;
    font-size: 0.75rem;
  }
`;

const ItemLink = styled(RouterLink)`
  color: inherit;
  text-decoration: none;
`;

const ShowTitleLink = styled(ItemLink)`
  font-weight: 600;
`;

const LiveIconContainer = styled.div`
  margin-top: 0.25rem;
`;

ScheduleItem.propTypes = {
  item: PropTypes.object,
  scrollToLive: PropTypes.bool
};

function ScheduleItem({ item, scrollToLive }) {
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const startTime = dayjs(item.startDateTimeUtc).tz(DISPLAY_TIMEZONE);
  const additionalProps = isMobile && item.episode ? {
    as: Link,
    component: 'button',
    onClick: () => handleEpisodeClick(item.episode)
  } : {};
  const hasEpisode = get(item, 'episode');
  const show = get(item, 'show');
  const episode = {...hasEpisode, show};
  const showRef = useRef(null);
  const active = item.airStatus === 'live';

  useEffect(() => {
    if (active) {
      // place live show in view
      showRef.current?.scrollIntoView();
      // counteract the windows offset from the above scroll function
      window.scrollTo(0, 0)
    }
  }, [scrollToLive]);

  const recordEvent = () => {
    dataLayerPush('Live Schedule prior episode clicked');
  };

  const handleEpisodeClick = (episode) => {
    const episodeUrl = get(episode, 'url');

    recordEvent();
    history.push(episodeUrl);
  };

  const episodeUrl = get(episode, 'url');

  return (
    <ShowContainer
      $active={active}
      $post={item.airStatus === 'post'}
      $episode={hasEpisode}
      {...additionalProps}
      ref={showRef}
    >
      <IconContainer>
        <PlayIcon icon="play" color="black" />
      </IconContainer>
  
      {active && (
        <LiveIconContainer>
          <LiveIndicatorAnimation />
        </LiveIconContainer>
      )}

      <ShowTimeGrid item xs={12} sm={3} md={3}>
        {startTime.format('h:mm A')}
      </ShowTimeGrid>
      <Grid item xs={12} sm={9} md={9}>
        <ShowTitle $episode={hasEpisode}>
          {!isMobile && item.episode ? (
            <ShowTitleLink to={episodeUrl} onClick={recordEvent}>{item.show?.title}</ShowTitleLink>
          ) : item.show?.title}

        </ShowTitle>
        {item.episodeTitle && (
          <ShowDetails $small>
            {!isMobile && item.episode ? (
              <ItemLink to={episodeUrl} onClick={recordEvent}>{item.episodeTitle}</ItemLink>
            ) : item.episodeTitle}
          </ShowDetails>
        )}
      </Grid>
      <Hidden smDown>
        {hasEpisode && <ActionButtons episode={episode}/>}
      </Hidden>
    </ShowContainer>
  );
}

export default React.memo(ScheduleItem);
