import React, { useState } from 'react';
import styled from 'styled-components';
import Toolbar from '@material-ui/core/Toolbar';
import { useTheme } from '@material-ui/core/styles';
import { ifNotProp, theme } from 'styled-tools';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { useLivePlayerContext } from 'context/LivePlayerContext';
import { dataLayerPush } from 'utils/dataLayer';
import Button from 'components/Button';
import Icon from 'components/Icon';

export const ControlsContainer = styled(Toolbar)`
  background-color: ${theme('colors.black')};
  border: 0.0625rem solid ${theme('colors.mutedGray')};
  display: grid;
  grid-template-columns: 70% 30%;
  min-height: auto;

  ${props => props.theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr 1fr;
  }

  &.MuiToolbar-gutters {
    padding-right: 0;
    padding-left: 0;
  }
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  min-height: 2.5rem;
`;

const ControlButton = styled(Button)`
  color: ${theme('colors.white')};
  font-size: 0.75rem;
  text-transform: uppercase;
  height: 100%;
  justify-content: center;
  border-radius: 0;
  padding: 0.8125rem;
  min-width: 12rem;
  transition: all .5s ease-out;

  svg {
    font-size: 1rem;
    fill: ${theme('colors.white')};
    margin-right: 0.5rem;
  }

  &.schedule {
    background: linear-gradient(to left, ${theme('colors.mutedGray')} 50%, ${theme('colors.black')} 50%);
    background-position: left bottom;
    background-size: 200% 100%;

    &.is-active {
      background-position: right bottom;
    }
  }

  &.tradefeed {
    background: linear-gradient(to right, ${theme('colors.mutedGray')} 50%, ${theme('colors.black')} 50%);
    background-position: right bottom;
    background-size: 200% 100%;

    &.is-active {
      background-position: left bottom;
    }
  }

  &.audio {
    border-right: 1px solid ${theme('colors.mutedGray')};

    &.is-active {
      background-color: ${theme('colors.mutedGray')};
    }
  }

  &:hover {
    background-color: ${theme('colors.mutedGray')};

    svg{
      fill: ${theme('colors.white')};
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }

  &.MuiButton-text:hover {
    color: ${theme('colors.white')};
  }

  .video-icon {
    fill: ${ifNotProp('disabled', theme('colors.white'), 'rgba(255, 255, 255, 0.26)')};

    &.video-icon-audio {
      font-size: 1.3rem;
    }

    &.focus {
      fill: ${theme('colors.white')};
    }
  }

  &.Mui-disabled {
    svg {
      fill: rgba(255, 255, 255, 0.26);
    }
  }

  ${props => props.theme.breakpoints.down('md')} {
    min-width: 0rem;
  }
`;

const AudioOnlyIcon = styled(Icon)`
  fill: ${theme('colors.white')};
`;

const DisclaimerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DisclaimerContainer = styled.div`
  p {
    border-bottom: 0;
  }
`;

function VideoControls({ disclaimer }) {
  const {
    state: { selectedEpisode, currentPage, totalPages, isDrawerOpen, isVideoMode },
    backToLive,
    displaySchedule,
    displayTrades,
    toggleVideoMode,
  } = useLivePlayerContext();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const device = isDesktop ? 'Desktop' : 'Mobile';
  const [activeButton, setActiveButton] = useState('schedule');

  const toggleAudioOnly = () => {
    if (isVideoMode) {
      dataLayerPush('Live Player audio-only enabled');
    } else {
      dataLayerPush('Live Player audio-only disabled');
    }
    window.scrollTo(0,0)
    toggleVideoMode();
  }

  const viewTrades = () => {
    dataLayerPush(`${device} Trade feed opened`);
    setActiveButton('tradefeed');
    displayTrades();
  }

  const viewSchedule = () => {
    dataLayerPush(`${device} Live Schedule opened`);
    setActiveButton('schedule');
    displaySchedule();
  }

  return (
    <ControlsContainer>
      <DisclaimerWrapper>
      {!selectedEpisode && (
        <ControlButton
          onClick={toggleAudioOnly}
          title='Audio Only'
          className={!isVideoMode ? 'is-active audio' : 'audio'}
        >
          <AudioOnlyIcon
            className='video-icon video-icon-audio'
            icon='headset'
            $active={!isVideoMode}
          />
          <div>Audio Only</div>
        </ControlButton>
      )}
      <DisclaimerContainer>
        {disclaimer}
      </DisclaimerContainer>
      </DisclaimerWrapper>
    </ControlsContainer>
  )
}

export default React.memo(VideoControls);
