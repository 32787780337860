import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

CurrencyFormat.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function CurrencyFormat({ value, ...props }) {
  if (!value) {
    return null;
  }

  return (
    <NumberFormat
      value={value}
      displayType='text'
      prefix='$'
      thousandSeparator
      decimalScale={2}
      {...props}
    />
  );
}

export default CurrencyFormat;
