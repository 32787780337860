import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import BaseIconButton from '@material-ui/core/IconButton';
import { useLivePlayerContext } from 'context/LivePlayerContext';
import Icon from 'components/Icon';
import Schedule from './Schedule';
import Trades from './Trades';

const Wrapper = styled.div`
  background-color: ${theme('colors.black')};
  height: 100%;
  position: relative;
  width: 100%;
`;

const IconButton = styled(BaseIconButton)`
  padding: 0;
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1;

  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }

  svg {
    color: ${theme('colors.white')};
    font-size: 1rem;
  }
`;

const sidePanelComponents = {
  schedule: Schedule,
  trades: Trades,
}

LivePlayerSidebar.propTypes = {};

function LivePlayerSidebar() {
  const { state: { sidePanelOpen }, closeMobileDrawer } = useLivePlayerContext();
  const trades = sidePanelOpen === 'trades';

  const SidePanelComponent = sidePanelComponents[sidePanelOpen] || Schedule;

  return (
    <Wrapper>
      {trades && (
        <IconButton onClick={() => closeMobileDrawer()}>
          <Icon icon='close' />
        </IconButton>
      )}
      <SidePanelComponent closeMobileDrawer={closeMobileDrawer} />
    </Wrapper>
  );
}

export default React.memo(LivePlayerSidebar);
