import React from 'react';
import get from 'lodash.get';
import sortBy from 'lodash.sortby';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import styled, { css } from 'styled-components';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { ifProp, theme } from 'styled-tools';
import CurrencyFormat from 'components/CurrencyFormat';
import PercentFormat from 'components/PercentFormat';
import { isBuy, isDebit } from 'utils/tradeHelpers';
import orderShape from 'shapes/orderShape';

const TradeStat = styled(Box)`
  align-items: flex-start;
  color: ${theme('colors.white')};
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  ${ifProp(
    'background',
    css`
      border-radius: 0.25rem;
      background-color: ${theme('colors.mutedDarkGray')};
      padding: 0.25rem 0.5rem;

      & > *:last-child {
        font-weight: 600;
      }
    `
  )}
`;

const TagsLabel = styled.p`
  color: ${theme('colors.white')};
`;

const Tags = styled.span`
  text-transform: uppercase;
  color: #3bca63;
  font-weight: 600;
  font-size: 0.83em;
`;

TradeStats.propTypes = {
  order: orderShape,
};

function TradeStats({ order }) {
  const {
    probabilityOfProfit,
    price,
    underlyingPrice,
    isEarningsPlay,
    isHedge,
    isScalpTrade,
    orderLegs,
  } = order;

  // Find expiration date by open leg that expires soonest.
  const expirationDate = get(
    sortBy(
      orderLegs.filter((v) => v.openClose === 'O'),
      'expirationDate'
    ),
    '[0].expirationDate'
  );
  const daysLeft = expirationDate
    ? differenceInCalendarDays(new Date(expirationDate), new Date()) + 1
    : 'N/A';
  // If any of the order legs are open, we'll treat this as open and therefore show days left.
  const isOpen = orderLegs && orderLegs.find((v) => v.openClose === 'O');

  const tags = [];

  if (isEarningsPlay) {
    tags.push('Earnings Play');
  }

  if (isHedge) {
    tags.push('Hedge');
  }

  if (isScalpTrade) {
    tags.push('Scalp Trade');
  }

  function getPostfix() {
    if (isDebit(order)) {
      return 'db';
    }
    return 'cr';
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TradeStat background alignItems='flex-end'>
            <div>POP</div>
            <div>
              {probabilityOfProfit !== null ? (
                probabilityOfProfit < 1 ? (
                  <>
                    &lt;
                    <PercentFormat value={1} decimalScale={0} />
                  </>
                ) : (
                  <PercentFormat
                    value={parseFloat(probabilityOfProfit)}
                    decimalScale={0}
                  />
                )
              ) : (
                'N/A'
              )}
            </div>
          </TradeStat>
        </Grid>
        <Grid item xs={6}>
          <TradeStat background alignItems='flex-end'>
            <div>Price</div>
            <div>
              <CurrencyFormat value={parseFloat(price)} decimalScale={3} />
              {getPostfix()}
            </div>
          </TradeStat>
        </Grid>
        <Grid item xs={6}>
          <TradeStat background alignItems='flex-end'>
            <span>Days Left</span>
            <span>{isOpen ? daysLeft : 'N/A'}</span>
          </TradeStat>
        </Grid>
        <Grid item xs={6}>
          <TradeStat background alignItems='flex-end'>
            <span>Orig</span>
            <span>
              <CurrencyFormat
                value={parseFloat(underlyingPrice)}
                decimalScale={3}
                fixedDecimalScale={3}
              />
            </span>
          </TradeStat>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {tags.length > 0 && (
            <TagsLabel>
              Tags &mdash; <Tags>{tags.join(', ')}</Tags>
            </TagsLabel>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default TradeStats;
