import React from 'react';
import get from 'lodash.get';
import sortBy from 'lodash.sortby';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import styled, { css } from 'styled-components';
import { ifProp, switchProp, theme } from 'styled-tools';
import BaseAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isBuy, BOUGHT, SOLD } from 'utils/tradeHelpers';
import TradeStats from '../../components/TradeStats';
import TradeLegs from '../../components/TradeLegs';
import orderShape from 'shapes/orderShape';

const TradeAction = styled.div`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;

  ${switchProp('action', {
    [SOLD]: css`
      color: ${theme('colors.ttRed')};
    `,
    [BOUGHT]: css`
      color: ${theme('colors.green')};
    `,
  })}
`;

const Strategy = styled.div`
  color: ${theme('colors.white')};
  font-family: ${theme('typography.fontFamilySecondary')};
  font-weight: 700;
  text-transform: uppercase;
`;

const Symbol = styled(Strategy)`
  font-size: 1.5rem;
  line-height: 1;
`;

const TradeStat = styled(Box)`
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;

  ${ifProp(
    'background',
    css`
      border-radius: 0.25rem;
      background-color: ${theme('colors.mutedDarkGray')};
      padding: 0.25rem 0.5rem;

      & > *:last-child {
        font-weight: 600;
      }
    `
  )}
`;

const Accordion = styled(BaseAccordion)`
  background-color: ${theme('colors.mutedGray')};
  box-shadow: none;

  &.MuiAccordion-root {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;

    && {
      padding: 0.75rem;
    }

    &.Mui-expanded {
      margin-bottom: 0;
    }

    .MuiAccordionSummary-root {
      padding-left: 0;
      padding-right: 0;

      &.Mui-expanded {
        min-height: 48px;
      }
    }

    .MuiAccordionSummary-content {
      margin: 0;
    }

    .MuiAccordionDetails-root {
      flex-direction: column;
      padding: 0;
    }
  }

  .MuiAccordionSummary-expandIcon {
    color: ${theme('colors.white')};
    padding: 0.5rem;
    position: absolute;
    right: 8px;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: ${theme('colors.ttRed')};
    }
  }
`;

TradeBlockAccordion.propTypes = {
  order: orderShape,
};

function TradeBlockAccordion({ order }) {
  const { orderLegs, strategy } = order;

  // Get the underlyingSymbol from the order legs.
  const underlyingSymbol = get(orderLegs, '[0].underlyingSymbol');
  // Find expiration date by open leg that expires soonest.
  const expirationDate = get(
    sortBy(
      orderLegs.filter((v) => v.openClose === 'O'),
      'expirationDate'
    ),
    '[0].expirationDate'
  );
  const boughtOrSold = isBuy(order) ? BOUGHT : SOLD;

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TradeStat alignItems='center'>
              <Symbol>{underlyingSymbol}</Symbol>
            </TradeStat>
          </Grid>
          <Grid item xs={6}>
            <TradeStat>
              <div>
                <TradeAction action={boughtOrSold}>{boughtOrSold}</TradeAction>
                <Strategy>{strategy}</Strategy>
              </div>
            </TradeStat>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <TradeStats order={order} />
        <TradeLegs order={order} />
      </AccordionDetails>
    </Accordion>
  );
}

export default TradeBlockAccordion;
