import React, { useState } from 'react';
import styled from 'styled-components';
import AvatarDetails from './components/AvatarDetails';
import TradeBlockAccordion from './components/TradeBlockAccordion';
import { dataLayerPush } from 'utils/dataLayer';
import orderShape from 'shapes/orderShape';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  > div {
    &:first-child {
      padding: 0.75rem;
      position: relative;
    }
  }
`;

TradeBlock.propTypes = {
  order: orderShape,
};

function TradeBlock({ order }) {
  const launchTradeDetail = () => {
    dataLayerPush('Single trade detail expanded open');
  }

  return (
    <Wrapper onClick={() => launchTradeDetail()}>
      <TradeBlockAccordion order={order} />
      <AvatarDetails order={order} />
    </Wrapper>
  );
}

export default TradeBlock;
