import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import orderShape from 'shapes/orderShape';
import TradeBlock from 'components/TradeBlock';

const Panel = styled(Grid)`
  color: ${theme('palette.common.white')};
`;

const TradeContainer = styled(Grid)`
  background: ${theme('colors.mutedGray')};
  border-radius: 0.75rem;
  flex: 1 0 auto;
  margin-bottom: 1.25rem;
`;

TradesItem.propTypes = {
  orders: PropTypes.arrayOf(orderShape),
};

function TradesItem({ orders }) {

  return (
    <Panel container direction='column'>
      {orders.map(order => (
        <TradeContainer item key={order.id}>
          <TradeBlock order={order} />
        </TradeContainer>
      ))}
    </Panel>
  );
}

export default React.memo(TradesItem);
