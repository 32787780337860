import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import format from 'date-fns/format';
import { theme } from 'styled-tools';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import orderShape from 'shapes/orderShape';
import Icon from 'components/Icon';

const TraderAvatar = styled(Avatar)`
  border-bottom-left-radius: 0.75rem;
  margin-right: 0.75rem;
`;

const TraderName = styled.div`
  align-items: center;
  display: flex;
  font-family: ${theme('typography.fontFamilySecondary')};
  font-weight: 600;
  line-height: normal;
  margin-right: 0.75rem;
  text-transform: uppercase;
`;

const DateTime = styled(Box)`
  font-family: ${theme('typography.fontFamilySecondary')};
`;

const GridContainer = styled(Grid)`
  background: ${theme('colors.mutedDarkGray')};
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  flex-wrap: nowrap;
  padding-right: 0.625rem;
`;

const TradeDate = styled.span`
  text-transform: uppercase;
`;

const TradeTime = styled.span`
  font-weight: 700;
`;

AvatarDetails.propTypes = {
  order: orderShape,
};

function AvatarDetails({ order }) {
  const { executedAt, filledAt, trader } = order;
  const { name, shortName, portraitUrl } = trader || {};

  const traderName = shortName || name;
  const tradeDate = new Date(filledAt || executedAt);

  return (
    <GridContainer container>
      <Grid item>
        <Box display='flex'>
          <TraderAvatar variant='square' alt={traderName} src={portraitUrl} imgProps={{ loading: 'lazy' }} />
          <TraderName>{traderName}</TraderName>
        </Box>
      </Grid>
      <Grid item container alignItems='center'>
        <DateTime ml='auto'>
          <TradeDate>{format(tradeDate, 'MMM d, yyyy')}</TradeDate>
          <TradeTime>{' '}{format(tradeDate, 'h:mmaa')}</TradeTime>
        </DateTime>
      </Grid>
    </GridContainer>
  );
}

export default AvatarDetails;
