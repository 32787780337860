import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import get from 'lodash.get';
import chunk from 'lodash.chunk';
import { useQuery } from '@apollo/client';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import config from 'config';
import useInterval from 'hooks/useInterval';
import TradesItem from 'routes/Home/components/LivePlayerSidebar/Trades/TradesItem';
import getOrdersQuery from './getOrders.graphql';
import AdSlot from 'components/AdSlot';

const TradesContainer = styled.div`
  background: ${theme('colors.black')};
  height: 100%;
  overflow: hidden;
`;

const Footer = styled.div`
  position: absolute;
  width: 100%;
  height: 4.375rem;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem 0;
  background-color: ${theme('colors.black')};
  overflow: hidden;

  ${(props) => props.theme.breakpoints.up('md')} {
    border-top: 0.0625rem solid ${theme('colors.mutedGray')};
    padding: 0.625rem 0;
  }
`;

const FooterAdContainer = styled.div`
  width: 320px;
  margin: auto;
`;

const Wrapper = styled.div`
  background: ${theme('colors.black')};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 1.25rem 0 7rem;
`;

const FeedHeader = styled(Toolbar)`
  background-color: ${theme('colors.black')};
  padding: 0 0.625rem;
  width: 100%;
  border-bottom: 1px solid ${theme('colors.mutedGray')};
  z-index: 1300;

  &.MuiToolbar-regular {
    min-height: 3rem;
  }

  img {
    width: 100px;
    height: auto;
    margin-right: 0.5rem;
  }
`;

const FeedTitle = styled(Typography)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 0.5rem;
  color: ${theme('colors.white')};
`;

const SkeletonWrapper = styled.div`
  height: 100%;
`;

const SkeletonGrid = styled(Grid)``;

const TradeSkeleton = styled.div`
  border-radius: 0.25rem;
  flex: 1 0 auto;
  height: 7rem;
  margin-bottom: 1.25rem;

  ${(props) => props.theme.breakpoints.down('sm')} {
    min-width: 18.75rem;
  }

  .MuiSkeleton-root {
    background-color: rgba(255, 255, 255, 0.11);
  }
`;

const TradesItemList = styled.div`
  overflow-y: ${({ isTouchDevice }) => (isTouchDevice ? 'auto' : 'hidden')};  
  padding: 0 0.625rem;

  &:hover {
    overflow-y: scroll;
    -webkit-padding-end: 0;

    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-thumb  {
      background: ${theme('colors.mutedGray')};
    }
  }
`;

Trades.propTypes = {
  isTouchDevice: PropTypes.bool,
  isOpen: PropTypes.bool,
};

function Trades({ isTouchDevice, isOpen }) {
  const { data, loading, error, refetch } = useQuery(getOrdersQuery, {
    skip: typeof window === 'undefined' || !isOpen,
    pollInterval: config.tradeOrders.pollInterval,
  });

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen, refetch]);

  useInterval(() => {
    if (isOpen) {
      refetch();
    }
  }, config.tradeOrders.pollInterval);

  const orders = get(data, 'orders.items', []);
  const chunkedOrders = chunk(orders, 2);

  return (
    <TradesContainer>
      <FeedHeader>
        <FeedTitle>Trade Feed</FeedTitle>
      </FeedHeader>
      <Wrapper isTouchDevice={isTouchDevice}>
        {error && (
          <Box mt={3}>
            <Alert severity='error'>
              Failed to load trades, please reload to try again.
            </Alert>
          </Box>
        )}
        {loading ? (
          <SkeletonWrapper>
            <SkeletonGrid container direction='column'>
              <TradeSkeleton>
                <Skeleton variant='rect' height='100%' />
              </TradeSkeleton>
              <TradeSkeleton>
                <Skeleton variant='rect' height='100%' />
              </TradeSkeleton>
              <TradeSkeleton>
                <Skeleton variant='rect' height='100%' />
              </TradeSkeleton>
              <TradeSkeleton>
                <Skeleton variant='rect' height='100%' />
              </TradeSkeleton>
            </SkeletonGrid>
          </SkeletonWrapper>
        ) : (
          <TradesItemList isTouchDevice={isTouchDevice}>
            {chunkedOrders.map((orders, index) => (
              <TradesItem key={index} orders={orders} />
            ))}
          </TradesItemList>
        )}
        <Footer>
          <FooterAdContainer>
            <AdSlot configId='adUnitLiveScheduleShortAdSpace' />
          </FooterAdContainer>
        </Footer>
      </Wrapper>
    </TradesContainer>
  );
}

export default React.memo(Trades);
