import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';
import ReactAudioPlayer from 'react-audio-player';

const SkeletonContainer = styled.div`
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
`;

const PlayerSkeleton = styled(Skeleton).attrs({ variant: 'rect' })`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

AudioPlayer.propTypes = {
  src: PropTypes.string,
  autoplay: PropTypes.oneOf([false, true, 'muted', 'play', 'any']),
};

function AudioPlayer({ src, autoplay = false }) {

  if (!src) {
    return (
      <SkeletonContainer>
        <PlayerSkeleton />
      </SkeletonContainer>
    );
  }

  return (
    <ReactAudioPlayer src={src} autoPlay={autoplay} controls />
  );
}

export default React.memo(AudioPlayer);
