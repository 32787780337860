import React from 'react';
import get from 'lodash.get';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import episodeShape from 'shapes/episodeShape';
import AddToPlaylist, {ACTION_FOLLOW_SHOW, Wrapper} from 'components/CardButtons/AddToPlaylist';
import { useHistory } from 'react-router-dom';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

export const ButtonContainer = styled.span`
  display: flex;
  visibility: hidden;

  ${Wrapper} {
    button {
      padding: 0;
    }

    svg {
      color: ${theme('colors.white')};
      font-size: 1rem;
    }
    
    &:hover {
      button {
        background-color: ${theme('colors.white')};
      }
    
      svg {
        fill: ${theme('colors.black')};
      }
    }
  }
`;

ActionButtons.propTypes = {
  episode: episodeShape,
};

function ActionButtons({ episode }) {
  const history = useHistory();


  const playEpisodeAction = {
    value: 'playEpisode',
    label: 'Play Episode',
    icon: <PlayArrowIcon fontSize='small' />,
    callback: () => history.push(get(episode, 'url'))
  }

  return (
    <ButtonContainer>
      <AddToPlaylist episode={episode} excludedActions={[ACTION_FOLLOW_SHOW]} includedActions={[playEpisodeAction]} />
    </ButtonContainer>
  );
}

export default React.memo(ActionButtons);
