import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import orderBy from 'lodash.orderby';
import Grid from '@material-ui/core/Grid';
import styled, { css } from 'styled-components';
import { ifProp, switchProp, theme } from 'styled-tools';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import Typography from '@material-ui/core/Typography';
import DateFormatter from 'components/DateFormatter';
import orderShape from 'shapes/orderShape';
import {
  getLegActionType,
  legBoughtOrSold,
  legOpenOrClose,
  formatStrikePrice,
  BOUGHT,
  SOLD
} from 'utils/tradeHelpers';

const OPEN = 'open';
const CLOSE = 'close';

const Wrapper = styled.div`
  margin-top: 1rem;
`;

const SectionTitle = styled(Typography)`
  color: ${theme('colors.borderGray')};
  font-family: ${theme('typography.fontFamilySecondary')};
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

const TradeLeg = styled(Grid)`
  align-items: center;
  border-bottom: 0.0625rem solid #555555;
  color: ${theme('colors.white')};
  display: flex;
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.625rem;
  font-weight: 500;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  padding-bottom: 0.375rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const BoughtSold = styled(Grid)`
  align-items: center;
  display: flex;
  min-width: 7.15rem;
  text-transform: uppercase;

  @media (max-width: ${theme('breakpoints.custom.mobileSm')}) {
    min-width: 6.5rem;
  }

  > div {
    display: flex;
    justify-content: center;
    padding: 0.4375rem 0.375rem;
    width: 50%;

    &:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-left-radius: 0.25rem;

      ${switchProp('action', {
        [SOLD]: css`
          background: ${theme('colors.ttRed')};
          border: 0.0625rem solid ${theme('colors.ttRed')};
        `,
        [BOUGHT]: css`
          background: ${theme('colors.green')};
          border: 0.0625rem solid ${theme('colors.green')};
        `
      })}
    }

    &:last-child {
      border-bottom-right-radius: 0.25rem;
      border-top-right-radius: 0.25rem;

      ${switchProp('action', {
        [SOLD]: css`
          border: 0.0625rem solid ${theme('colors.ttRed')};
        `,
        [BOUGHT]: css`
          border: 0.0625rem solid ${theme('colors.green')};
        `
      })}
    }
  }
`;

const Quantity = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  min-width: 1rem;
`;

const LegStat = styled(Grid)`
  display: flex;
  min-height: 2.4375rem;
  min-width: 5.25rem;
  text-transform: uppercase;

  @media (max-width: ${theme('breakpoints.custom.mobileSm')}) {
    min-width: 4.65rem;
  }

  > div {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0.375rem;

    &:first-child {
      background: ${theme('colors.mutedDarkGray')};
      border-bottom-left-radius: 0.25rem;
      border-top-left-radius: 0.25rem;
      width: 65%;
    }

    &:last-child {
      background: #807f83;
      border-bottom-right-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      width: 35%;
    }
  }

  ${ifProp(
    '$hidden',
    css`
      visibility: hidden;
    `
  )}
`;

TradeLegs.propTypes = {
  order: orderShape,
};

function TradeLegs({ order }) {
  const { orderType, orderLegs } = order;
  const sortedOrderLegs = orderBy(orderLegs, ['strikePrice'], ['desc']);

  const formatDecimals = (value) => {
    if (value >= 1) {
      return parseInt(value).toFixed();
    } else {
      return value;
    }
  }

  const daysLeft = (expirationDate) => {
    return expirationDate
      ? differenceInCalendarDays(new Date(expirationDate), new Date()) + 1
      : 'n/a';
  }

  return (
    <Wrapper>
      <SectionTitle>Trade Legs</SectionTitle>
      {sortedOrderLegs.map((leg, index) => {
        const actionType = getLegActionType(leg);
        const boughtOrSold = legBoughtOrSold(actionType);
        const openOrClose = legOpenOrClose(actionType);
        const expirationDate = get(leg, 'expirationDate', '');
        const strikePrice = get(leg, 'strikePrice', 'n/a');
        const callOrPut = get(leg, 'callOrPut');

        return (
          <TradeLeg key={`leg-${index}`} container spacing={1}>
            <BoughtSold action={boughtOrSold} item>
              <div>{boughtOrSold}</div>
              <div>{openOrClose}</div>
            </BoughtSold>
            <Quantity item>
              {boughtOrSold === SOLD ? '-' : null}
              {formatDecimals(get(leg, 'quantity', 'n/a'))}
            </Quantity>
            <LegStat item $hidden={!expirationDate}>
              <div>
                {expirationDate && (
                  <DateFormatter date={get(leg, 'expirationDate')} format='MMM DD' />
                )}
              </div>
              <div>{expirationDate ? daysLeft(expirationDate) + 'd' : ''}</div>
            </LegStat>
            <LegStat item $hidden={!strikePrice && !callOrPut}>
              <div>{formatStrikePrice(strikePrice)}</div>
              <div>{callOrPut}</div>
            </LegStat>
          </TradeLeg>
        );
      })}
    </Wrapper>
  );
}

export default TradeLegs;
