import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BaseBox from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import LiveVideoStatusBar from 'components/VideoPlayer/LiveVideoStatusBar';
import ScheduleItem from '../ScheduleItem';
import AdSlot from 'components/AdSlot';

const Panel = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  border-bottom: 0.0625rem solid ${theme('colors.mutedGray')};
  font-size: 1rem;
  font-weight: 600;
  font-family: ${theme('fonts.robotoCondensed')};
`;

const Box = styled(BaseBox)`
  overflow: ${({ isTouchDevice }) => (isTouchDevice ? 'auto' : 'hidden')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 3.125rem;
  padding-right: 0.5rem;

  &:hover {
    overflow-y: scroll;
    -webkit-padding-end: 0;

    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-thumb  {
      background: ${theme('colors.mutedGray')};
    }
  }
`;

const GridContainer = styled(Grid)`
  padding: 1rem 0;
`;

const Footer = styled.div`
  position: absolute;
  width: 100%;
  height: 4.375rem;
  bottom: 0;
  padding: 1rem 0;
  background-color: ${theme('colors.black')};
  overflow: hidden;

  ${props => props.theme.breakpoints.up('md')} {
    border-top: 0.0625rem solid ${theme('colors.mutedGray')};
    padding: 0.625rem 0;
  }
`;

const FooterAdContainer = styled.div`
  width: 320px;
  margin: auto;
`;

SchedulePanel.propTypes = {
  closeMobileDrawer: PropTypes.func,
  scheduledShows: PropTypes.array,
  isTouchDevice: PropTypes.bool,
};

function SchedulePanel({
  closeMobileDrawer,
  scheduledShows,
  isTouchDevice
}) {
  const [scrollToLive, setScrollToLive] = useState(false);

  async function handleClickStatus() {
    await setScrollToLive(true);
    setScrollToLive(false);
  }

  return (
    <Panel>
      <Header>
        <LiveVideoStatusBar onClickStatus={handleClickStatus} />
      </Header>
      <Box isTouchDevice={isTouchDevice}>
        <GridContainer container>
          {scheduledShows?.map((item, index) => (
            <ScheduleItem
              item={item}
              key={`item-${index}`}
              scrollToLive={scrollToLive}
            />
          ))}
        </GridContainer>
        <Footer>
          <FooterAdContainer>
            <AdSlot configId='adUnitLiveScheduleShortAdSpace' />
          </FooterAdContainer>
        </Footer>
      </Box>
    </Panel>
  );
}

export default React.memo(SchedulePanel);
