import sortBy from 'lodash.sortby';

const BUY_TO_OPEN = 'buytoopen';
const BUY_TO_CLOSE = 'buytoclose';
const SELL_TO_OPEN = 'selltoopen';
const SELL_TO_CLOSE = 'selltoclose';
export const BOUGHT = 'bought';
export const SOLD = 'sold';

const STRATEGY_BUTTERFLY = 'BUTTERFLY';

const ORDER_TYPE = {
  market: 'market',
  limit: 'limit',
  netCredit: 'net_credit',
  netDebit: 'net_debit',
  stopMarket: 'stop_market',
  stopLimit: 'stop_limit',
  exercise: 'exercise',
};

export function getLegActionType(orderLeg) {
  if (!orderLeg.openClose) {
    return null;
  }

  const isOpen = orderLeg.openClose === 'O';
  const isOption = orderLeg.assetType.toUpperCase() === 'O';

  if (isOption) {
    switch (orderLeg.action) {
      case 'buy':
      case 'buytoopen':
      case 'buytoclose':
      case 'B':
        return isOpen ? BUY_TO_OPEN : BUY_TO_CLOSE;
      case 'sell':
      case 'selltoopen':
      case 'selltoclose':
      case 'S':
        return isOpen ? SELL_TO_OPEN : SELL_TO_CLOSE;
      default:
        console.error(`Unknown action: ${orderLeg.action}`);
        return null;
    }
  } else {
    switch (orderLeg.action) {
      case 'buy':
      case 'buytoopen':
      case 'buytoclose':
      case 'buytocover':
      case 'B':
        return isOpen ? BUY_TO_OPEN : BUY_TO_CLOSE;
      case 'sell':
      case 'selltoopen':
      case 'selltoclose':
      case 'selltocover':
      case 'S':
        return isOpen ? SELL_TO_OPEN : SELL_TO_CLOSE;
      case 'sellshort':
      case 'SS':
        return SELL_TO_OPEN;
      case 'BC':
        return BUY_TO_CLOSE;
      case 'E':
        return null;
      case 'EX':
        return null;
      default:
        console.error(`Unknown action: ${orderLeg.action}`);
        return null;
    }
  }
};

export function legBoughtOrSold(actionType) {
  switch (actionType) {
    case BUY_TO_OPEN:
      return 'bought';

    case BUY_TO_CLOSE:
      return 'bought';

    case SELL_TO_OPEN:
      return 'sold';

    case SELL_TO_CLOSE:
      return 'sold';

    default:
      return null;
  }
}

export function legOpenOrClose(actionType) {
  switch (actionType) {
    case BUY_TO_OPEN:
      return 'open';

    case SELL_TO_OPEN:
      return 'open';

    case BUY_TO_CLOSE:
      return 'close';

    case SELL_TO_CLOSE:
      return 'close';

    default:
      return null;
  }
}

export function getOrderType(order) {
  switch (order.orderType) {
    case 'market':
    case 'M':
      return ORDER_TYPE.market;

    case 'limit':
    case 'L':
      return ORDER_TYPE.limit;

    case 'net_credit':
    case 'C':
      return ORDER_TYPE.netCredit;

    case 'net_debit':
    case 'D':
      return ORDER_TYPE.netDebit;

    case 'stop_market':
    case 'S':
      return ORDER_TYPE.stopMarket;

    case 'stop_limit':
    case 'X':
      return ORDER_TYPE.stopLimit;

    case 'EX':
      return ORDER_TYPE.exercise;

    default:
      return null;
  }
};

export function sortOrderLegs(orderLegs = []) {
  return sortBy(orderLegs, v => parseFloat(v.strikePrice));
}

export function isBuy(order) {
  if (STRATEGY_BUTTERFLY === order.strategy.toUpperCase()) {
    return isLegDebit(sortOrderLegs(order.orderLegs)[0]);
  } else {
    return isDebit(order);
  }
}

export function isDebit(order) {
  if (order.orderLegs.length > 1) {
    return getOrderType(order) === ORDER_TYPE.netDebit;
  } else {
    return isLegDebit(sortOrderLegs(order.orderLegs)[0]);
  }
}

export function isLegDebit(orderLeg) {
  const actionType = getLegActionType(orderLeg);

  return actionType === BUY_TO_OPEN || actionType === BUY_TO_CLOSE;
}

export function formatStrikePrice(value) {
  if (value >= 1) {
    const splitDecimal = value.split('.');
    const decimalValue = +splitDecimal[1];

    if (decimalValue > 0) {
      return parseFloat(value);
    } else {
      return parseInt(value);
    }
  } else {
    return value;
  }
}
