import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import BaseDrawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import Schedule from '../LivePlayerSidebar/Schedule';
import Trades from '../LivePlayerSidebar/Trades';
import News from '../LivePlayerSidebar/News'
import Icon from 'components/Icon';
import livePlayerSidebarShape from 'shapes/livePlayerSidebarShape';
import { isTouchEnabled } from 'utils/scrollBarHelpers';
import { dataLayerPush } from 'utils/dataLayer';

const ButtonContainer = styled.div`
  width: 3.45rem;
  height: 100%;
  flex-direction: column;
  align-content: start;
  align-items: center;
  display: grid;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;

  ${({ isDrawerOpen }) => isDrawerOpen && `
    z-index: 1000;
    border-left: 1px solid rgba(239, 239, 239, .2);
    background-color: rgba(29, 25, 27, .85);
  `}

  ${({ isDrawerOpen }) => !isDrawerOpen && `
    z-index: 1;
  `}

  ${props => props.theme.breakpoints.down('xs')} {
    width: 100%;
    height: 3rem;
    grid-column-gap: 1px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: end;
    padding: 0;
    position: ${props => (props.isDrawerOpen ? 'fixed' : 'relative')};
    top: ${props => (props.isDrawerOpen ? '3.78rem' : '0')};
  }
`;

const StickyContent = styled.div`
  position: sticky;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;

const Drawer = styled(BaseDrawer)`
  // if tablet, only open drawer 50%
  ${props => props.theme.breakpoints.up('sm')} {
    .MuiDrawer-paper {
      z-index: 1;
      width: 40%;
      min-width: 20rem;
      max-width: 35rem;
    }
  }
  ${props => props.theme.breakpoints.down('xs')} {
    .MuiDrawer-paper {
      width: 100%;
      min-width: 320px;
    }
  }
`;

const ScheduleButton = styled.button`
  width: 2rem;
  height: 2rem;
  grid-column-gap: 0.5rem;
  color: ${theme('colors.darkCharcoal')};
  white-space: nowrap;
  cursor: pointer;
  background-color: ${props => props.isActive ? 'transparent' : theme('colors.limeGreen')};
  border: 1px solid ${theme('colors.limeGreen')};
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  font-family: Icomoon, sans-serif;
  font-size: .65rem;
  display: flex;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  ${props => props.theme.breakpoints.down('xs')} {
    width: 100%;
    height: 3rem;
    border-radius: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.2rem;
`;

const StyledDrawer = styled(Drawer)`
  display: ${props => !props.open ? 'none' : 'block'};

  .MuiDrawer-paper {
    right: 3.35rem;
    position: absolute;

    ${props => props.theme.breakpoints.down('xs')} {
      position: fixed;
      right: 0;
      top: 6.8rem;
      max-height: calc(100% - 6rem);
    }
  }

  .MuiPaper-root {
    backdrop-filter: blur(5px);
    background-color: rgba(29, 25, 27, .9);
    overflow-x: hidden;
  }
`;

const StyledIcon = styled(Icon)`
  width: 0.9rem;
  height: 0.95rem;
`;

const sidePanelComponents = {
  schedule: Schedule,
  trades: Trades,
}

ScheduleTradeNews.propTypes = {
  data: livePlayerSidebarShape,
};

function ScheduleTradeNews({ data }) {
  const newsData = data?.menuItems?.filter((tab) => tab.tabHeader.toLowerCase() === 'news')
  const [openDrawer, setOpenDrawer] = useState(null);
  const [isTouchDevice, setIsTouchDevice] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    const touchDevice = isTouchEnabled();
    setIsTouchDevice(touchDevice);
  }, []);

  useEffect(() => {
    if (openDrawer && isMobile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [openDrawer, isMobile]);

  const useStyles = makeStyles((theme) => ({
    customTooltip: {
      backgroundColor: 'rgba(29, 25, 27, .95)',
      fontSize: '.55rem',
      padding: '0.15rem 0',
      color: theme.colors.limeGreen,
      marginTop: '-0.4rem',
      marginBottom: '-5rem',
      top: '0',
    }
  }));

  const classes = useStyles();

  const toggleDrawer = (drawer) => (event) => {
    const deviceSize = isMobile ? 'Mobile' : 'Desktop';
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    if (openDrawer === drawer) {
      setOpenDrawer(null);
    } else {
      dataLayerPush(`${deviceSize} ${drawer} drawer opened`);
      setOpenDrawer(drawer);
    }
  };

  return (
    <>
      <ButtonContainer isDrawerOpen={openDrawer}>
        <Tooltip title='SCHEDULE' classes={{ tooltip: classes.customTooltip }}>
          <StyledButtonContainer>
            <ScheduleButton onClick={toggleDrawer('schedule')} isActive={openDrawer === 'schedule'}>
              <StyledIcon
                icon={'bulletList'}
                alt='icon'
                color={openDrawer === 'schedule' ? theme.colors.limeGreen : 'black'}
              />
            </ScheduleButton>
          </StyledButtonContainer>
        </Tooltip>
        <Tooltip title='TRADES' classes={{ tooltip: classes.customTooltip }}>
          <StyledButtonContainer>
            <ScheduleButton onClick={toggleDrawer('trades')} isActive={openDrawer === 'trades'}>
              <StyledIcon
                icon={'reverse'}
                color={openDrawer === 'trades' ? theme.colors.limeGreen : 'black'}
                alt='icon'
              />
            </ScheduleButton>
          </StyledButtonContainer>
        </Tooltip>
        <Tooltip title='NEWS' classes={{ tooltip: classes.customTooltip }}>
          <StyledButtonContainer>
            <ScheduleButton onClick={toggleDrawer('news')} isActive={openDrawer === 'news'}>
              <StyledIcon
                icon={'news'}
                color={openDrawer === 'news' ? theme.colors.limeGreen : 'black'}
                alt='icon'
              />
            </ScheduleButton>
          </StyledButtonContainer>
        </Tooltip>
      </ButtonContainer>
      <StyledDrawer
        anchor='right'
        open={openDrawer === 'schedule'}
        onClose={toggleDrawer(null)}
        variant='persistent'
        ModalProps={{
          BackdropProps: {
            invisible: true,
          }
        }}
      >
        <StickyContent
          tabIndex={0}
          role="button"
        >
          <Schedule isTouchDevice={isTouchDevice} />
        </StickyContent>
      </StyledDrawer>
      <StyledDrawer
        anchor='right'
        open={openDrawer === 'trades'}
        onClose={toggleDrawer(null)}
        variant='persistent'
        ModalProps={{
          BackdropProps: {
            invisible: true,
          }
        }}
      >
        <Trades isOpen={openDrawer === 'trades'} isTouchDevice={isTouchDevice} />
      </StyledDrawer>
      <StyledDrawer
        anchor='right'
        open={openDrawer === 'news'}
        onClose={toggleDrawer(null)}
        variant='persistent'
        ModalProps={{
          BackdropProps: {
            invisible: true,
          }
        }}
      >
        {newsData && <News data={newsData} isTouchDevice={isTouchDevice} />}
      </StyledDrawer>
    </>
  );
}

export default React.memo(ScheduleTradeNews);
